html {
  overflow-wrap: break-word;
  touch-action: manipulation;
}

:root {
  --base-size: #{$base-size};
  --primary: #{$primary};
  --primary-invert: #{$primary-invert};
  --secondary: #{$link};
  --secondary-invert: #{$link-invert};
  --text: #{$text};
  --text-invert: #{$text-invert};
  --grey: #717171;
  --grey-invert: #{ findColorInvert($grey) };
  --background: #{$body-background-color};
  --background-invert: #{$background-invert};
  --logo-width: #{$logo-width};
}
