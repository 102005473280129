.input {
  height: auto;
  padding: 15px 20px;

  &::placeholder {
    color: #b5b5b5;
  }
}

.field.has-none-border-input {
  margin-bottom: 40px;

  .input,
  .textarea {
    border-radius: 0;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    box-shadow: none;
  }
}

.control.has-prefix {
  .prefix {
    border: 1px solid transparent;
    color: #b5b5b5;
    position: absolute;
    top: 0;
    left: 0;
    padding: 15px 10px 15px 20px;
  }

  input {
    padding-left: 50px;
  }
}
