html {
  background-color: var(--background);
}

body {
  color: var(--text);
}

strong {
  color: var(--text);
}

.title {
  &--large {
    font-size: 32px;
    font-weight: 700;
    line-height: 48px;
    margin-bottom: 32px;
  }

  &.is20px {
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    color: #000000;
  }
}

.field {
  &:not(:last-child) {
    margin-bottom: 15px;
  }
}

hr {
  background-color: #dcdcdc;
  border: none;
  box-sizing: border-box;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 18px;
}

.mt-auto {
  margin-top: auto;
}

.mb-auto {
  margin-bottom: auto;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}
