.image.is-square video {
    height: 100%;
    width: 100%;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 4px;
    object-fit: contain;
  }