.image {
  &.is200x200 {
    width: 200px;
    height: 200px;
  }

  &.is113x113 {
    width: 113px;
    height: 113px;
  }
}

img {
  transition: filter 0.1s ease-in-out;
  &.lazyloading.blur-up {
    filter: blur(4px);
    -webkit-filter: blur(4px);
  }
}
