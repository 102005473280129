.modal {
  display: flex;
  visibility: hidden;

  &-background {
    opacity: 0;
    transition: all 0.3s ease;
  }

  &-card {
    opacity: 0;
    transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
  }

  &.slide-left {
    .modal-card {
      transform: translateX(-20%);
    }
  }

  &.slide-left-desktop {
    @media screen and (min-width: 769px) {
      .modal-card {
        transform: translateX(-20%);
      }
    }
  }

  &.slide-right {
    .modal-card {
      transform: translateX(20%);
    }
  }

  &.slide-right-mobile {
    @media screen and (max-width: 768px) {
      .modal-card {
        transform: translateX(20%);
      }
    }
  }

  &.is-active {
    visibility: visible;

    .modal-background {
      opacity: 1;
    }

    &.slide-left,
    &.slide-right,
    &.slide-left-desktop,
    &.slide-right-mobile {
      .modal-card {
        transform: translateX(0);
        opacity: 1;
      }
    }
  }
}
