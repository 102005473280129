@import 'default';
@import 'variable';
@import 'helper';
@import 'components';
@import 'global';
@import 'animate';
@import '~bulma-divider';
@import '~bulma-steps';

.input[disabled] {
  -webkit-text-fill-color: #7a7a7a;
  opacity: 1;
}
