.animated {
  animation-duration: 0.3s;
  animation-fill-mode: both;

  &.infinite {
    animation-iteration-count: infinite;
  }
}

@media print and (prefers-reduced-motion: reduce) {
  .animated {
    animation-duration: 1ms !important;
    transition-duration: 1ms !important;
    animation-iteration-count: 1 !important;
  }
}

@keyframes slide-in-down {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slide-in-down {
  animation-name: slide-in-down;
}
