// stylelint-disable at-rule-no-unknown

@mixin generator-responsive($device, $type, $base-size) {
  $d: '';

  @if $device != '' {
    $d: -#{$device};
  }

  @for $i from 0 to 9 {
    &-#{$i}#{$d} { #{$type}: calc(#{$i} * #{$base-size}) !important; }
    &t-#{$i}#{$d} { #{$type}-top: calc(#{$i} * #{$base-size}) !important; }
    &r-#{$i}#{$d} { #{$type}-right: calc(#{$i} * #{$base-size}) !important; }
    &b-#{$i}#{$d} { #{$type}-bottom: calc(#{$i} * #{$base-size}) !important; }
    &l-#{$i}#{$d} { #{$type}-left: calc(#{$i} * #{$base-size}) !important; }
  }
}

@mixin generator($type, $base-size) {
  @include generator-responsive('', $type, $base-size);

  @media screen and (max-width: 768px) {
    @include generator-responsive(mobile, $type, $base-size);
  }

  @each $device, $minWidth, $maxWidth in $devices {
    @media screen and (min-width: $minWidth) {
      @include generator-responsive($device, $type, $base-size);
    }

    @media screen and (min-width: $minWidth) and (max-width: $maxWidth) {
      @include generator-responsive(#{$device}-only, $type, $base-size);
    }
  }

  @media screen and (min-width: 1408px) {
    @include generator-responsive(fullhd, $type, $base-size);
  }
}

$devices:
  'tablet' 769px 1023px,
  'desktop' 1024px 1215px,
  'widescreen' 1216px 1407px;

.m {
  @include generator(margin, #{var(--base-size)});
}

.p {
  @include generator(padding, #{var(--base-size)});
}
